import React from 'react';
import { VStack, Input, InputGroup, Select, Text } from '@chakra-ui/react';
import { Unit } from './Form.types';

interface Props {
    mapScale: number;
    groundScale: number;
    onMapScaleChange: (val: number) => void;
    onGroundScaleChange: (val: number) => void;
    onMapUnitChange: (val: string) => void;
    onGroundUnitChange: (val: string) => void;
}

function ScaleSelect({
    mapScale,
    groundScale,
    onMapScaleChange,
    onGroundScaleChange,
    onMapUnitChange,
    onGroundUnitChange,
}: Props) {
    return (
        <VStack>
            <InputGroup>
                <Input
                    flexGrow={2}
                    defaultValue={mapScale}
                    onChange={(e) => onMapScaleChange(Number(e.target.value))}
                />
                <Select variant="filled" defaultValue={1} onChange={(e) => onMapUnitChange(e.target.value)}>
                    <option value={Unit.Cm}>cm</option>
                    <option value={Unit.Mm}>mm</option>
                </Select>
            </InputGroup>
            <Text>=</Text>
            <InputGroup>
                <Input defaultValue={groundScale} onChange={(e) => onGroundScaleChange(Number(e.target.value))} />
                <Select variant="filled" defaultValue={10} onChange={(e) => onGroundUnitChange(e.target.value)}>
                    <option value={Unit.M}>m</option>
                    <option value={Unit.Km}>km</option>
                </Select>
            </InputGroup>
        </VStack>
    );
}

export default ScaleSelect;
