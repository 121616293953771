/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { InputGroup, Input, InputRightElement, FormLabel, FormControl, FormHelperText } from '@chakra-ui/react';

interface Props {
    label: string;
    description: string;
    unit: string;
    onInputChange: (value: number) => void;
}

function FormInput({ label, description, unit, onInputChange }: Props, ref: React.ForwardedRef<HTMLInputElement>) {
    return (
        <FormControl>
            <FormLabel
                mt={{ base: '20px', md: '0' }}
                textAlign="center"
                fontWeight="bold"
                fontSize={{ base: 'lg', md: '26px' }}
            >
                {label}
            </FormLabel>
            <InputGroup>
                <Input
                    ref={ref}
                    type="number"
                    onChange={(e) => onInputChange(Number(e.target.value))}
                    size="lg"
                    variant="filled"
                    textAlign="center"
                />
                {/* eslint-disable-next-line react/no-children-prop */}
                <InputRightElement children={<strong>{unit}</strong>} height="48px" />
            </InputGroup>
            <FormHelperText>{description}</FormHelperText>
        </FormControl>
    );
}

export default React.forwardRef(FormInput);
