import React from 'react';
import { Text, Heading, Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

const StyledBox = styled(Box)`
    transform: rotate(20deg);
`;

function Intro() {
    return (
        <>
            <Heading mb="20px" letterSpacing="-2px">
                What the scale!? <StyledBox display={{ base: 'none', md: 'inline-block' }}>📐</StyledBox>
            </Heading>
            <Text mb={{ base: '0', md: '30px' }} maxW="570px" fontSize={{ base: 'medium', md: 'large' }}>
                A simple tool to help you draw maps, plans and diagrams with ease. Just set your scale and the
                measurements you would like to convert.
            </Text>
        </>
    );
}

export default Intro;
