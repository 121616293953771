import React from 'react';
import './App.css';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Form from 'components/Form';

const theme = extendTheme({
    fonts: {
        heading: `'Roboto Mono', monospace`,
        body: `'Source Sans Pro', sans-serif`,
    },
});

function App() {
    return (
        <ChakraProvider theme={theme}>
            <Form />
        </ChakraProvider>
    );
}

export default App;
