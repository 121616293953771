import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    HStack,
    VStack,
    Stat,
    StatHelpText,
    StatNumber,
    Text,
    useDisclosure,
    Box,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Unit } from './Form.types';
import ScaleSelect from './ScaleSelect';
import FormInput from './FormInput';
import Intro from './Intro';

const Wrapper = styled(Box)`
    background: rgba(255, 255, 255, 0.85);
`;

const Card = styled(VStack)`
    background: white;
    border-radius: 12px;
    display: block;
`;

function Form() {
    const [mapScale, setMapScale] = useState(1);
    const [groundScale, setGroundScale] = useState(10);
    const [scaleFactor, setScaleFactor] = useState(0);
    const [scaleDiff, setScaleDiff] = useState(100);
    const [mapMeasurement, setMapMeasurement] = useState(1);
    const [groundMeasurement, setGroundMeasurement] = useState(10);
    const [mapUnit, setMapUnit] = useState<string>(Unit.Cm);
    const [groundUnit, setGroundUnit] = useState<string>(Unit.M);
    const mapInputRef = useRef<HTMLInputElement>(null);
    const groundInputRef = useRef<HTMLInputElement>(null);
    const drawerBtnRef = useRef<HTMLButtonElement>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const setInputValue = (el: React.RefObject<HTMLInputElement>, value: number) => {
        if (el.current) {
            // eslint-disable-next-line no-param-reassign
            el.current.value = (Math.round((value + Number.EPSILON) * 100) / 100).toString();
        }
    };

    useEffect(() => {
        setScaleDiff(() => {
            if (mapUnit === Unit.Cm) {
                if (groundUnit === Unit.M) {
                    return 100;
                }
                if (groundUnit === Unit.Km) {
                    return 100000;
                }
            }
            if (mapUnit === Unit.Mm) {
                if (groundUnit === Unit.M) {
                    return 10000;
                }
                if (groundUnit === Unit.Km) {
                    return 10000000;
                }
            }
            return 0;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groundUnit, mapUnit]);

    useEffect(() => {
        setInputValue(mapInputRef, mapScale);
        setInputValue(groundInputRef, groundScale);
    }, [mapScale, groundScale]);

    useEffect(() => {
        setScaleFactor(() => {
            if (groundUnit === Unit.M || groundUnit === Unit.Km) {
                return groundScale * scaleDiff;
            }
            return 0;
        });
    }, [groundUnit, mapUnit, mapScale, groundScale, scaleDiff]);

    useEffect(() => setInputValue(mapInputRef, mapMeasurement), [mapMeasurement]);

    useEffect(() => setInputValue(groundInputRef, groundMeasurement), [groundMeasurement]);

    return (
        <>
            <Card
                position={{ base: 'relative', md: 'fixed' }}
                right={{ base: '0', md: '20px' }}
                top={{ base: '0', md: '20px' }}
                padding={{ base: '20px 25px 25px', md: '20px' }}
                ml={{ base: '15px', md: '0' }}
                mb={{ base: '20px', md: '0' }}
                w={{ base: 'calc(100% - 30px)', md: 'auto' }}
            >
                <Stat>
                    <StatNumber>{`${mapScale}${mapUnit} = ${groundScale}${groundUnit}`}</StatNumber>
                    <StatHelpText>{`Scale factor: ${mapScale}:${scaleFactor}${mapUnit}`}</StatHelpText>
                </Stat>
                <Button ref={drawerBtnRef} colorScheme="purple" onClick={onOpen} w={{ base: '100% ', md: 'auto' }}>
                    Set your scale
                </Button>
            </Card>
            <Wrapper
                padding={{ base: '25px', md: '40px 40px 50px' }}
                ml={{ base: '15px', md: '0' }}
                w={{ base: 'calc(100% - 30px)', md: '100%' }}
                borderRadius={{ base: '12px', md: '24px' }}
            >
                <Intro />
                <HStack wrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormInput
                        ref={mapInputRef}
                        label="On paper"
                        description="Measurement on your map or drawing."
                        unit={mapUnit}
                        onInputChange={(val) => setGroundMeasurement(((scaleFactor / mapScale) * val) / scaleDiff)}
                    />
                    <Text
                        display={{ base: 'none', md: 'inline-block' }}
                        pt="15px"
                        fontSize="2xl"
                        fontWeight="bold"
                        pl="10px"
                        pr="10px"
                    >
                        =
                    </Text>
                    <FormInput
                        ref={groundInputRef}
                        label="On the ground"
                        description="Measurement on the ground."
                        unit={groundUnit}
                        onInputChange={(val) => setMapMeasurement((val / scaleFactor) * (scaleDiff * mapScale))}
                    />
                </HStack>
                <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={drawerBtnRef}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Set your scale</DrawerHeader>
                        <DrawerBody>
                            <ScaleSelect
                                mapScale={mapScale}
                                groundScale={groundScale}
                                onMapScaleChange={setMapScale}
                                onGroundScaleChange={setGroundScale}
                                onMapUnitChange={setMapUnit}
                                onGroundUnitChange={setGroundUnit}
                            />
                        </DrawerBody>
                        <DrawerFooter>
                            <Button variant="outline" mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="purple" onClick={onClose}>
                                Done
                            </Button>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </Wrapper>
        </>
    );
}

export default Form;
